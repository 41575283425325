.footer-page {
    padding: 2rem 0 0 0;
    background-color: var(--primary-color);
}


.footer-container {
    background-color: var(--primary-color);
    width: 100%;
    height: 100%;
    padding: 3rem 0 0 0;
}

.footer-container-logo {
    display: flex;
    justify-content: center;
    background-color: white;
    width: fit-content;
    margin: auto;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.footer-container-logo-image {
    width: 200px;
}

.footer-container-logo-image img {
    width: 100%;
    height: 100%;

}

.footer-container-flex {
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding: 2rem 0;
}

.footer-container-card {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: transparent;
    color: white;
    position: relative;
    padding-right: 2rem;
}

.footer-container-card::after {
    content: '';
    background-color: var(--background-color);
    width: 1px;
    height: 70%;
    position: absolute;
    right: 0;
}

.footer-container-card h1 {
    font: normal 700 30px 'Cinzel';
}

.footer-container-card ul li {
    list-style-type: none;
    padding: 0.5rem;
    display: flex;
}

.footer-container-card:nth-child(1){
    text-align: center;
}

.footer-container-card:nth-child(2) ul li {
    justify-content: center;
    text-align: center;
}

.footer-container-email {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    /* background-color: red; */
    border-top: 1px solid var(--background-color);
    border-bottom: 1px solid var(--background-color);
    padding: 1rem 0;
    width: 100%;
}

.footer-container-email-input {
    display: flex;
    position: relative;
    width: 300px;
    padding: 2rem 0;
}

.footer-container-email-mantine-input {
    width: 100%;
}

.footer-container-email-btn {
    position: absolute;
    right: 0;
}

.footer-container-email-title h1 {
    font-family: 'Cinzel';
    font-weight: 600;
    color: white;
}

.footer-container-email-icons {
    display: flex;
    gap: 1rem;
    padding: 2rem 0;
}

.home-weather-container-card:nth-child(1) .footer-container-email-icons {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;
}

.footer-container-email-icons h2 {
    font: normal 500 50px 'Cinzel';
}

.footer-container-email-icons-tabler-icons {
    color: var(--background-color);
    cursor: pointer;
    transition: all 700ms ease-in-out;
}

.footer-container-email-icons-tabler-icons:hover {
    transform: scale(1.5);
}

.footer-container-email-btn {
    background-color: var(--background-color);
}

.footer-container-email-btn:hover {
    background-color: var(--blue-color);
}

.footer-copyrights {
    padding: 1rem;
    text-align: center;
}

.footer-copyrights a {
    color: white;
}
.footer-copyrights a:hover {
    transform: scale(1.5);
}

@media (max-width:1100px) {
    .footer-container-email-title h1 {
        font: normal 700 20px 'Cinzel';

    }
}

@media (max-width:900px) {

    .footer-container-flex {
        padding: 2rem 0rem;
    }

    .footer-container-card {
        width: 100%;
    }

    .footer-container-email {
        gap: 1rem;
    }
}


@media(max-width:768px) {
    .footer-container-flex {
        flex-direction: column;
        gap: 0;
        padding: 1rem;
        align-items: flex-start;
    }

    .footer-container-card::after {
        display: none;
    }

    .footer-container-email {
        flex-direction: column;
    }
}

@media(max-width:500px) {
    .footer-container-email-input {
        width: 300px;
    }
}