.header-page {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
}

.header-container {
    display: flex;
    align-items: center;
    gap: 8rem;
    padding: 1rem 0;
    width: fit-content;
    margin: auto;
}

.header-container-content {
    display: flex;
    gap: 3rem;
}

.header-container-div-text-number {
    font-weight: bold;
    font-size: 20px;
}


.header-container-div {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.header-container-div-title {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
}


.header-container-div-logo {
    width: 200px;
}

.header-container-div-logo img {
    width: 100%;
    height: 100%;
}

.header-container-div-btn {
    background-color: var(--primary-color);
    padding: 0 2rem;
    transition: all 300ms ease-in-out;
}

.header-container-div-btn:hover {
    background-color: var(--background-color);
}

.header-container-menu-icon {
    display: none;
}


.nav-link {
    text-decoration: none;
    color: white;
}


@keyframes slideIn {
    from {
        left: -100%;
    }

    to {
        left: 0%;
    }
}


@media(max-width:1400px) {
    .header-container {
        width: 100%;
        justify-content: center;
        gap: 2rem;
        padding: 0;
    }

    .header-container-content {
        gap: 3rem;
    }
}

@media(max-width:1200px) {
    .header-container {
        width: 100%;
        justify-content: space-between;
        padding: 1rem 2rem;
        gap: 1rem;
    }

    .header-container-content:nth-child(1) {
        display: none;
    }
}


@media(max-width:768px) {
    .header-container-content {
        display: none;
    }

    .header-container-menu-icon {
        display: block;
    }

    .header-container-div-logo {
        width: 100px;
    }


    .header-page-nav-bar {
        width: 100%;
        left: -100%;
        display: none;
    }

    .display-block {
        display: block;
        left: 0%;
        position: absolute;
        animation: slideIn 300ms ease-in-out forwards;
    }

}