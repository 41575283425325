/*Rooms Header */

.room-page {
    background-image: url("../../Assets/Background/BackgroundPattern.jpg");
}

.room-header-title-image {
    background-image: url('../../Assets/Images/Image10.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    position: relative;
    isolation: isolate;
}

.room-header-title-image::before {
    content: "";
    background-color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    inset: 0;
    z-index: -1;
}

.room-header-title-image h1 {
    text-transform: uppercase;
    font: normal bold 50px 'Cinzel';
    cursor: pointer;
    transition: all 700ms ease-in-out;
}

.room-header-title-image h1:hover {
    transition: all 700ms ease-in-out;
    transform: scale(1.2);
}

.room-header-title-image p:hover {
    transition: all 700ms ease-in-out;
    transform: scale(1.2);
}

.room-header-title-image h1::first-letter {
    font-size: 60px;
}

.room-header-title-image p {
    transition: all 700ms ease-in-out;
    font-family: 'Poppins';
    text-transform: uppercase;
    cursor: pointer;
}


/*Rooms Header End */


/* Rooms Container */

.room-container {
    width: 100%;
    height: 100%;
    padding: 2rem 15rem;
    display: flex;
    gap: 3rem;
    align-items: center;
}

.room-container-images {
    position: relative;
    top: -10rem;
    width: 80%;
    margin: auto;
    height: 80vh;
    display: flex;
    padding: 1rem;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    gap: 2rem;
    flex-direction: column;
}

.room-container-image-swiper {
    width: 100%;
    height: 100%;
}

.room-container-image-swiper-slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.room-container-image-swiper-slide img {
    width: 100%;
    height: 100%;
}

.room-container-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
}

.room-container-image-swiper {
    width: 100%;
    height: 100%;
}

.room-container-image-swiper-slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.room-container-image-swiper .swiper-button-prev,
.room-container-image-swiper .swiper-button-next {
    position: absolute;
    width: 10px !important;
    height: 10px !important;
    color: white !important;
}

.room-container-image-swiper .swiper-button-prev:after,
.room-container-image-swiper .swiper-button-next:after {
    font-size: 20px !important;
}



.room-container-image-animation {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.500);
    opacity: 0.5;
}

.room-container-image:hover .room-container-image-animation {
    left: 0;
    animation: leftToRight 700ms ease-in-out;
}

.room-container-image:hover img {
    transform: scale(1.2);
    transition: all 700ms ease-in-out;
}

@keyframes leftToRight {
    from {
        left: 100%;
    }

    to {
        left: 0;
    }
}

.room-container-image img {
    width: 100%;
    height: 100%;
    transition: all 700ms ease-in-out;
}

.room-container-content {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}


.room-container-content-title h1 {
    font: normal 700 45px 'Cinzel';
    padding-top: 1rem;
}


.room-container-content-title p {
    font: normal 500 30px 'Poppins';
    background-color: var(--primary-color);
    width: fit-content;
    margin: auto;
    padding: 0 1rem;
    color: white;
}


.room-container-content-title-list {
    width: fit-content;
    position: relative;
    right: -2rem;

}

/* Rooms Container End */



/* Icons */

.rooms-icons-container {
    display: flex;
    width: 100%;
    gap: 6rem;
    justify-content: center;
    position: relative;
    top: -7rem;
}

.rooms-icons-container-svg {
    width: 50px;
    height: 50px;
}

.rooms-icons-container-svg img {
    width: 100%;
    height: 100%;
}

.room-amenities-container {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    gap: 5rem;
    position: relative;
    top: -3rem;
    padding: 1rem;
}

.room-check-in-check-out {
    width: 100%;
    padding: 1rem;
    display: flex;
    gap: 4rem;
    justify-content: center;
}

.room-check-in,
.room-check-out {
    width: 400px;
    height: 200px;
    padding: 1rem;
    text-align: center;
}

.room-check-in-title h1,
.room-check-out-title h1 {
    text-align: center;
    font: normal 700 40px 'Cinzel';
    color: var(--primary-color);
}

.room-check-in,
.room-check-out {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Icons End */





@keyframes toptobottom {
    from {
        top: 100%;
    }

    to {
        top: 0;
    }
}

.rooms-images-list-images img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* Images List   End */
@media(max-width:1850px) {
    .room-container {
        padding: 2rem;
    }
}

@media(max-width:1450px) {
    .rooms-images-list {
        width: 95%;
    }
}

@media(max-width:1300px) {
    .room-container {
        height: 100%;
        gap: 1rem;
        align-items: center;
    }

    .room-container-content-title h1 {
        font: normal 700 40px 'Cinzel';
    }
}

@media(max-width:1060px) {
    .room-container {
        flex-direction: column;
    }

    .room-container-images {
        width: 90%;
        padding: 1rem;
        top: -5rem;
    }

    .rooms-icons-container {
        top: -3rem;
    }

    .room-amenities-container {
        top: 0;
    }

    .room-container-content {
        padding: 0;
    }

    .rooms-images-list {
        display: grid;
    }

    .rooms-images-list-images {
        width: 100%;
        height: 100%;
    }

    .room-container-content-title-list {
        width: fit-content;
        position: relative;
        right: 0rem;

    }

    .rooms-icons-container {
        gap: 3rem;
    }
}

@media(max-width:768px) {
    .room-header-title-image {
        height: 35vh;
    }

    .room-container-content-title h1 {
        text-align: center;
    }

    .room-container {
        padding: 1rem;
    }

    .room-header-title-image h1 {
        font: normal bold 30px 'Cinzel';
    }

    .rooms-images-list {
        padding: 0.5rem;
    }

    .room-amenities-container {
        grid-template-columns: auto;
        gap: 2rem;
    }

    .rooms-icons-container {
        gap: 1rem;
    }

    .room-container-images {
        height: 50vh;
        top: -4rem;
    }

    .rooms-icons-container {
        top: -2rem;
    }

    .room-check-in-check-out {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        width: 100%;
        padding: 1rem 0 2rem 0;
        overflow: hidden;
    }

    .room-check-in,
    .room-check-out {
        height: 100%;
        padding: 0;
    }
}

@media(max-width:500px) {
    .room-container-images {
        width: 100%;
        height: 37vh;
    }

    .rooms-icons-container {
        padding: 0 1rem;
    }

    .room-check-in,
    .room-check-out {
        gap: 0;
    }
}