.swipper-container {
    width: 100%;
    height: 80vh;
    cursor: pointer;
}

.swipper-react-swipper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swipper-react-swipper-slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
}


.swipper-react-swipper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swipper-react-swipper-slide-content {
    width: 100%;
    height: 100%;
    display: flex;
    left: 0%;
    bottom: 0%;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    text-align: center;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.300);
    color: white;
    text-transform: uppercase;
}

.swipper-react-swipper-slide-content h1:nth-child(1) {
    font: normal 600 25px 'Cinzel';
    padding-bottom: 1rem;
}

.swipper-react-swipper-slide-content h1:nth-child(2) {
    font: normal 600 70px/1 'Cinzel';
}

.swipper-react-swipper-slide-content h1:nth-child(3) {
    font: normal 600 60px 'Cinzel';
    color: var(--background-color);
    text-shadow: 2px 2px 2px black;
}


.swiper-animation {
    animation: slowLeft 3000ms ease-in-out forwards;
}

.swiper-animation2 {
    animation: slowRight 3000ms ease-in-out forwards;

}

.swiper-animation3 {
    animation: bottomTop 3000ms ease-in-out forwards;
}

@keyframes slowLeft {
    from {
        left: 100%
    }

    to {
        left: 0%;
    }
}

@keyframes slowRight {
    from {
        left: -100%
    }

    to {
        left: 0%;
    }
}

@keyframes bottomTop {
    from {
        bottom: 100%;
    }

    to {
        bottom: 0%;
    }
}



.swipper-react-swipper-slide-content .swipper-react-swipper .swiper-vertical>.swiper-pagination-bullets,
.swipper-react-swipper .swiper-pagination-vertical.swiper-pagination-bullets {
    left: 2% !important;
}

.swipper-react-swipper .swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
    background: white !important;
    /* border: 1px solid black; */
}


/* Media Query  */

@media(max-width:1100px) {
    .swipper-react-swipper-slide-content h1:nth-child(1) {
        font: normal 600 25px 'Cinzel';
    }

    .swipper-react-swipper-slide-content h1:nth-child(2) {
        font: normal 600 70px 'Cinzel';
    }

    .swipper-react-swipper-slide-content h1:nth-child(3) {
        font: normal 600 60px 'Cinzel';
    }
}

@media(max-width:768px) {
    .swipper-react-swipper-slide-content h1:nth-child(1) {
        font: normal 600 25px 'Cinzel';
    }

    .swipper-react-swipper-slide-content h1:nth-child(2) {
        font: normal 600 50px 'Cinzel';
    }

    .swipper-react-swipper-slide-content h1:nth-child(3) {
        font: normal 600 40px 'Cinzel';
    }

}

@media(max-width:500px) {

    .swipper-container {
        width: 100%;
        height: 40vh;
    }

    .swipper-react-swipper-slide-content h1:nth-child(1) {
        font: normal 600 15px 'Cinzel';
    }

    .swipper-react-swipper-slide-content h1:nth-child(2) {
        font: normal 600 40px 'Cinzel';
    }

    .swipper-react-swipper-slide-content h1:nth-child(3) {
        font: normal 600 30px 'Cinzel';
    }
    .swipper-react-swipper .swiper-pagination-bullet {
        width: 7px !important;
        height: 7px !important;
        background: white !important;
        /* border: 1px solid black; */
    }
}

/* Media Query  End */