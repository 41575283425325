.navbar-container {
    display: flex;
    width: 100%;
    gap: 2rem;
    justify-content: center;
    background-color: var(--primary-color);
    overflow: hidden;
}

.navbar-container .nav-link {
    text-decoration: none;
    color: white;
}

.navbar-container ul {
    display: flex;
    gap: 5rem;
}

.navbar-container ul li {
    list-style-type: none;
    text-transform: uppercase;
    font: normal 500 20px 'Poppins';
    width: 100%;
    height: 100%;
    padding: 0.5rem;
}

.navbar-container-content-text {
    display: flex;
    color: white;
    align-items: center;
    gap: 1rem;
}


@media(max-width:900px) {
    .navbar-container ul {
        gap: 2rem;
    }
}

@media(max-width:768px) {
    .navbar-container {
        display: flex;
        flex-direction: column;
        background-color: var(--primary-color);
        padding: 2rem;
        position: relative;
        left: -100%;
        transition: all 300ms ease-in-out;
        z-index: 1099;
    }

    .navbar-container ul {
        gap: 1rem;
        flex-direction: column;
    }

    .navbar-container ul li {
        width: fit-content;
    }

    .open-nav-bar {
        left: 0;
        transition: all 300ms ease-in-out;
    }

}