/* Home page About us */

.home-position-relative {
    position: relative;
}

.home-page-cloud {
    width: 100%;
    position: absolute;
    height: 300px;
    z-index: 1098;
    overflow: hidden;
    left: 0;
    right: 0;
    top: -2rem;
}


.home-page-cloud img {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.5;
    top: 0;
    animation: animate calc(8s*var(--i)) linear infinite;
}

@keyframes animate {
    from {
        left: -100%;
    }

    to {
        left: 100%;
    }
}



.home-about-us-container-section {
    background-image: url("../../Assets/Background/BackgroundPattern.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}


.home-about-us-container {
    width: 100%;
    padding: 5rem;
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: space-around;
}


/* Content CSS */

.home-about-us-container-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}


.home-about-us-container-content-para:nth-child(1) h1 {
    font: normal bold 60px/1 'Cinzel';
    text-transform: uppercase;
}

.home-about-us-container-content-para:nth-child(1) p {
    color: var(--primary-color);
}

.home-about-us-container-content-para:nth-child(1) h1::first-letter {
    font: normal bold 80px/1 'Cinzel';
    text-transform: uppercase;
}

.home-about-us-container-content-para:nth-child(2) {
    font-family: 'Poppins';
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 700px;
}

.home-about-us-container-content-btn {
    background-color: var(--primary-color);
    height: 6vh;
    padding: 0 2rem;
    transition: all 300ms ease-in-out;
}

.home-about-us-container-content-btn:hover {
    background-color: var(--background-color);
    padding: 0 2rem;
    transition: all 300ms ease-in-out;
}


/* Content CSS End */

.home-about-us-container-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.home-about-us-container-grid-card {
    display: flex;
    gap: 2rem;
}

.home-about-us-container-grid-mantine-card {
    width: 350px;
    padding: 0 !important;
    height: 400px;
}

.home-about-us-container-grid-mantine-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.home-about-us-container-grid-mantine-card:nth-child(1) {
    position: relative;
    bottom: 10rem;
    z-index: 1098;
}

.home-about-us-container-grid-mantine-card:nth-child(2) {
    background-color: var(--primary-color);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--background-color);
    position: relative;
    bottom: 2rem;
}

.home-about-us-container-grid-mantine-card:nth-child(2) h1 {
    font: normal bold 120px 'Cinzel';
}

.home-about-us-container-grid-mantine-card:nth-child(2) p {
    font: normal bold 25px 'Cinzel';
}



.home-about-us-container-grid-card-right-mantine-card {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-family: 'Poppins';
}

.home-about-us-container-grid-card-right-mantine-card div:nth-child(1) {
    padding: 1rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.2px solid rgba(128, 128, 128, 0.635);
    transition: all 300ms ease-in-out;
}


.home-about-us-container-grid-card:nth-child(2) {
    display: grid;
    grid-template-columns: auto auto;
}

.home-about-us-container-grid-card-right-mantine-card {
    cursor: pointer !important;
}

.home-about-us-container-grid-card-right-mantine-card:hover div:nth-child(1) {
    border: 1px solid var(--primary-color);
}

.home-about-us-container-grid-card-right-mantine-card div:nth-child(1):hover {
    background-color: var(--primary-color);
}

.home-about-us-container-grid-card-right-mantine-card-icon {
    transition: all 300ms ease-in-out;
    color: var(--primary-color);
}

.home-about-us-container-grid-card-right-mantine-card div:nth-child(1):hover .home-about-us-container-grid-card-right-mantine-card-icon {
    color: var(--background-color);
}

/* .home-about-us-container-grid-mantine-card  */
/* Home page About us End */



/* Activity  */

.home-activity-container {
    background-color: #41566bd1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10rem;
    height: 70vh;
}

.home-activity-container-left {
    background-color: #41566B;
    width: 900px;
    height: 100%;
}

.home-activity-container-left img {
    width: 100%;
    height: 100%;
}

.home-activity-container-right {
    display: flex;
    flex-direction: column;
    color: white;
    gap: 2rem;
    padding-left: 3rem;
}


.home-activity-container-right-content:nth-child(1) h1 {
    width: fit-content;
    font: normal 700 80px 'Cinzel';
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.759);
    color: var(--background-color);
}

.home-activity-container-right-content:nth-child(1) p {
    width: fit-content;
    font: normal 500 20px/1 'Cinzel';
}

.home-activity-container-right-content:nth-child(2) p {
    max-width: 80%;
    font-weight: 100;
    font-family: 'Lato';
    font-size: 25px;
}


/* Carousel sWIPER */

.home-activity-carousel-swiper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.home-activity-carousel-swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.room-container-image-swiper-slide img {
    width: 100%;
    height: 100%;
}

.home-activity-carousel-swiper .swiper-button-prev,
.home-activity-carousel-swiper .swiper-button-next {
    position: absolute;
    width: 10px !important;
    height: 10px !important;
    color: white !important;
}

.home-activity-carousel-swiper .swiper-button-prev:after,
.home-activity-carousel-swiper .swiper-button-next:after {
    font-size: 20px !important;
}



/* Carousel sWIPER End */

/* Activity End */




/* Customer Review  */

.home-customer-review-container {
    width: 100%;
    height: 100%;
    background-image: url('../../Assets/Background/BackgroundImage3.jpg');
    background-size: cover;
    padding: 4rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}


.home-customer-review-container-image {
    width: 30%;
    height: 35vh;
    border-radius: 15px;
    position: relative;
}

.home-customer-review-container-image-animation {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.500);
    display: flex;
    /* gap: 3rem; */
    justify-content: space-evenly;
    padding: 3rem;
    color: white;
    flex-direction: column;
}

.home-customer-review-container-image-animation-award {
    position: absolute;
    background-color: var(--background-color);
    width: 120px;
    height: 120px;
    border-radius: 10px;
    right: -3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 1rem;
}

.home-customer-review-container-image-animation-btn {
    width: fit-content;
    height: 4vh;
    background-color: var(--background-color);
    transition: all 700ms ease-in-out;
}

.home-customer-review-container-image-animation-btn:hover {
    background-color: var(--primary-color);
}

.home-customer-review-container-image-animation h5 {
    font: normal 400 20px 'Lato';
}

.home-customer-review-container-image-animation h1 {
    font: normal 700 50px 'Cinzel';
    /* color: var(--background-color); */
}

.home-customer-review-container-image img {
    width: 100%;
    border-radius: 15px;
    height: 100%;
    object-fit: cover;
}

.home-customer-review-container-swiper {
    width: 50%;
    height: 100%;
}

.home-customer-review-container-swiper-js {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-customer-review-container-swiper-js-slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-customer-review-container-swiper-js-slide-content-review p {
    width: 100%;
    --max-lines: 3;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
}


.home-customer-review-container-swiper-js-slide-content {
    width: 100%;
    height: 35vh;
    text-align: start;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem 5rem;
}

.home-customer-review-container-swiper-js-slide-content-name {
    width: 100%;
}

.home-customer-review-container-swiper-js-slide-content-review-para {
    font: normal bold 20px 'Cinzel';
}

/* Customer Review  End */



/* Nearby Places */

.home-nearby-container {
    width: 100%;
    height: 75vh;
    position: relative;
    display: flex;
    align-items: center;
}

.home-nearby-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-nearby-content {
    position: absolute;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.300);
    height: 100%;
    top: 0;
    left: 0;
    color: white;
}

.home-nearby-content-white {
    color: white;
}

.home-nearby-content h1 {
    font: normal 700 80px 'Cinzel';
    color: var(--background-color);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.759);
}


.home-nearby-content p:nth-child(2) {
    font: normal 20px 'Poppins';
}



/* Near by Grid */
.background-image {
    background-image: url("../../Assets/Background/BackgroundPattern.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2rem;
}

.background-image h1 {
    font: normal 700 50px 'Cinzel';
    padding: 2rem 0 0 0;
}

/* Nearby Places End */


.media-query-display-block {
    margin: 1rem auto;
}

.home-nearby-content-images9 {
    display: none;
}


/* Weather */

.home-weather-container {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 2rem;
    justify-content: space-evenly;
    padding: 4rem 2rem 0 2rem;
}

.home-weather-container-card {
    width: 400px;
    position: relative;
    height: 250px;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    justify-content: center;
    align-items: center;
}

.home-weather-container-card h1 {
    font: normal 700 30px 'Cinzel';
}

.home-weather-container-card h3 {

    font: normal 500 15px 'Poppins';
}

.home-weather-container-card::before {
    content: '';
    position: absolute;
    right: -5rem;
    width: 0.5px;
    height: 70%;
    background-color: #000;
}

.home-weather-container-card:last-child::before {
    display: none;
}


/* Weather End */




/* Near By Places  */

.home-nearby-places {
    width: 100%;
    height: 100%;
    display: flex;
}

.home-nearby-places-images {
    width: 300px;
    height: 220px;
    overflow: hidden;
    transition: all 700ms ease-in-out;
}

.home-nearby-places-images:hover {
    transition: all 700ms ease-in-out;
    transform: scale(1.2);
}


.home-nearby-places-images img {
    transition: all 700ms ease-in-out;
    width: 100%;
    height: 100%;
}


/* Near By Places End */


/* Media Query */

@media(max-width:1600px) {
    .home-about-us-container-grid-mantine-card {
        width: 250px;
        padding: 0 !important;
        height: 350px;
    }

    .home-about-us-container-content-para:nth-child(1) h1 {
        font: normal bold 30px/1 'Cinzel';
    }

    .home-about-us-container-content-para:nth-child(1) h1::first-letter {
        font: normal bold 40px/1 'Cinzel';
    }

    .home-about-us-container-content-para:nth-child(2) h1 {
        font: normal 500 30px 'Poppins';
    }

    .home-about-us-container-grid-mantine-card:nth-child(1) {
        bottom: 8rem;
    }

    .home-about-us-container-grid-mantine-card:nth-child(2) {
        bottom: 2rem;
    }


    .home-activity-container {
        height: 100%;
        gap: 2rem;
    }

    .home-activity-container-left {
        background-color: #41566B;
        width: 750px;
        height: 100%;
    }

    .home-nearby-content>div p {
        width: 600px;
    }

}


@media(max-width:1500px) {
    .home-weather-container-card::before {
        right: -2rem;
    }
}




@media(max-width:1400px) {

    .home-about-us-container {
        padding: 2rem;
    }

    .home-about-us-container-grid-mantine-card:nth-child(1) {
        bottom: 6rem;
    }

    .home-about-us-container-grid-mantine-card:nth-child(2) {
        bottom: 0rem;
    }

    .home-activity-container-left {
        width: 600px;
    }

    .home-nearby-content-grid {
        height: 450px;
    }

    .media-query-display-block {
        display: grid;
        width: 1100px;
        height: auto;
    }

    .home-weather-container-card::before {
        right: 0rem;
    }

    .home-nearby-places-images {
        height: 180px;
    }

    .home-about-us-container-grid-card-right-mantine-card div:nth-child(1) {
        padding: 0.5rem;
    }


    .home-about-us-container-grid-card:nth-child(2) {
        font: normal 15px 'Poppins';
    }
}


@media(max-width:1200px) {
    .home-weather-container {
        padding: 2rem;
    }

    .home-weather-container-card h1 {
        font: normal 700 25px 'Cinzel';
    }

    .home-activity-container-right {
        padding: 0;
    }   
}

@media(max-width:1060px) {
    .home-customer-review-container {
        flex-direction: column;
        padding: 2rem;
        gap: 1rem;
    }

    .home-activity-container-right {
        padding: 0;
    }

    .home-customer-review-container-image {
        width: 70%;
        height: 70%;
    }

    .home-customer-review-container-swiper {
        width: 80%;
    }

    .home-customer-review-container-swiper-js-slide-content {
        height: 100%;
    }


    .home-about-us-container {
        flex-direction: column;
    }

    .home-about-us-container-grid-mantine-card:nth-child(1),
    .home-about-us-container-grid-mantine-card:nth-child(2) {
        bottom: 0rem;
    }

    .home-about-us-container-content {
        width: 100%;
        align-items: center;
    }

    .home-about-us-container-content-para:nth-child(2) {
        max-width: 100%;
    }

    .home-about-us-container-content-para:nth-child(1) p {
        text-align: center;
    }

    .home-about-us-container-grid {
        width: 100%;
    }

    .home-about-us-container-grid-card {
        justify-content: center;
    }

    .home-about-us-container-grid {
        display: grid;
        gap: 2rem;
    }

    .home-activity-container {
        flex-direction: column;
        align-items: center;
    }

    .home-activity-container-left {
        width: 100%;
        height: 70vh;
    }

    .home-activity-container {
        padding-bottom: 2rem;
    }

    .home-activity-container-right {
        align-items: center;
    }

    .home-activity-container-right-content:nth-child(1) h1 {
        text-align: center;
        max-width: auto;
    }

    .home-activity-container-right-content:nth-child(2) p {
        max-width: 100%;
        padding-top: 0;
        padding: 0 2rem 0 2rem;
        font-family: 'Poppins';
    }

    .home-customer-review-container-swiper-js-slide-content-review-para {
        font: normal bold 15px 'Cinzel';
    }

    .home-customer-review-container-swiper-js {
        width: 100%;
        height: 100%;
    }

    .home-nearby-content {
        flex-direction: column;
        width: 100%;
    }

    .home-nearby-container {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
    }

    .media-query-display-none {
        display: none;
    }

    .media-query-display-block {
        padding: 2rem 1rem;
        display: grid;
        width: 100%;
        height: 900px;
    }

    .home-nearby-container {
        width: 100%;
    }

    .home-nearby-content>div p {
        width: 100%;
    }

    .home-weather-container {
        flex-direction: column;
        align-items: center;
    }

    .home-weather-container-card::before {
        display: none;
        gap: 0;
    }

    .home-weather-container-card {
        height: 100%;
    }

    .home-nearby-places {
        display: grid;
        grid-template-columns: auto auto auto;
    }

    .home-nearby-places-images {
        width: 100%;
    }

    .home-nearby-places-images:last-child {
        display: none;
    }
}


@media(max-width:768px) {
    .home-customer-review-container-swiper-js-slide-content-review p {
        width: 100%;
        --max-lines: 3;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: var(--max-lines);
    }

    .home-customer-review-container-swiper-js-slide-content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 2rem;
    }

    .home-nearby-container {
        width: 100%;
        height: 100%;
    }

    .home-nearby-content h1 {
        font: normal 50px 'Cinzel';
    }

    .home-nearby-content p:nth-child(3) {
        padding-top: 1rem;
        font: normal 25px 'Poppins';
    }

    .home-nearby-content-images9 {
        display: block;
    }


    .home-nearby-content-images1 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .home-nearby-content-images2 {
        grid-column: 3 / span 2;
        grid-row: span 2;
    }

    .home-nearby-content-images3 {
        grid-column: 5 / span 2;
        grid-row: span 2;
    }

    .home-nearby-content-images4 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .home-nearby-content-images5 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .home-nearby-content-images6 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .home-nearby-content-images7 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .home-nearby-content-images8 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .home-nearby-content-images9 {
        grid-column: span 2;
        grid-row: span 2;
    }

    .media-query-display-block {
        padding: 1rem 0.5rem;
    }

    .home-nearby-places-images {
        height: 150px;
    }
}


@media(max-width:650px) {
    .home-about-us-container-grid-card {
        width: 100%;
        align-items: center;
        flex-direction: column;
    }

    .home-about-us-container-grid-mantine-card {
        width: 100%;
        padding: 0 !important;
        height: 350px;
    }

    .home-about-us-container {
        padding: 1rem;
    }

    .home-about-us-container-grid-card:nth-child(2) {
        grid-template-columns: auto;
    }

    .home-about-us-container-content-para:nth-child(2) h1 {
        font: normal 600 20px 'Poppins';
    }

    .home-about-us-container-grid-card {
        gap: 1rem;
    }

    .home-activity-container-left {
        padding: 0;
        width: 100%;
        height: 50vh;
    }

    .home-activity-container-right-content:nth-child(1) h1 {
        width: fit-content;
        font: normal 500 40px 'Cinzel';
        margin: auto;
    }

    .home-activity-container-right {
        gap: 1rem;
    }

    .home-activity-container-right-content:nth-child(2) p {
        text-align: center;
        padding: 0;
    }

    .media-query-display-block {
        height: 700px;
    }

    .home-nearby-content h1 {
        font: normal 35px 'Cinzel';
    }

    .home-nearby-content p:nth-child(3) {
        padding-top: 1rem;
        font: normal 18px 'Poppins';
    }

    .home-customer-review-container-image {
        width: 90%;
        height: 100%;
    }

    .home-customer-review-container-swiper {
        width: 100%;
    }

    .home-customer-review-container-image-animation h1 {
        font: normal 700 30px 'Cinzel';
    }

    .home-customer-review-container-image-animation-award {
        width: 70px;
        height: 70px;
        right: -2rem;
        display: none;
    }

    .home-customer-review-container-swiper-js .swiper-button-next:after,
    .home-customer-review-container-swiper-js .swiper-rtl .swiper-button-prev:after,
    .home-customer-review-container-swiper-js .swiper-button-prev:after,
    .home-customer-review-container-swiper-js .swiper-rtl .swiper-button-next:after {
        font-size: 1rem;
    }

    .home-customer-review-container-swiper-js-slide-content {
        padding: 0;
    }

    .home-customer-review-container {
        padding: 2rem 1rem;
    }
}


@media(max-width:500px) {
    .home-activity-container-left {
        height: 40vh;
    }

    .media-query-display-block {
        height: 400px;
    }

    .home-nearby-content p:nth-child(3) {
        padding-top: 1rem;
        font: normal 100 12px 'Poppins';
    }

    .home-activity-container-right-content:nth-child(2) p {
        max-width: 100%;
        font-family: 'Poppins';
        font-size: 20px;
        padding: 1rem;
    }

    .media-query-display-block {
        margin: 2rem auto;
    }

    .home-page-cloud {
        width: 100%;
        height: 150px;
    }

    .home-weather-container-card {
        width: 100%;
    }

    .footer-container-email-icons {
        padding: 2rem 0 0 0;
    }

    .home-weather-container {
        gap: 0;
    }

    .background-image h1 {
        font: normal 700 30px 'Cinzel';
        padding: 0;
    }

    .background-image {
        text-align: center;
        padding: 0 0 2rem 0;
    }
}


@media (max-width:400px) {
    .home-customer-review-container-image-animation h1 {
        font: normal 700 30px 'Cinzel';
    }

    .home-customer-review-container-image-animation h5 {
        font: normal 400 15px 'Lato';
    }

    .home-activity-container-right-content:nth-child(1) p {
        text-align: center;
    }

    .home-customer-review-container-image-animation {
        padding: 2rem;
    }

    .home-nearby-places-images {
        height: 110px;
    }

    .home-page-cloud {
        width: 100%;
        height: 100px;
        top: 0;
    }

}

/* Media Query End */