/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Lato&family=Poppins&display=swap');


/* Default CSS Removing */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}


/* Scroll Bar CSS */

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Scroll Bar CSS End */


/* Root Colors */

:root {
    --primary-color: #41566B;
    --background-color: #CF9D6C;
    --blue-color: #2B7DC0;
}

/* Root Colors End*/

/* Sticky Header  */

.router-header {
    position: sticky;
    top: 0;
    z-index: 1099;
    background-color: white;    
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.drop-down-navbar{
    position: sticky;
    top: 5rem;
    z-index: 1099;
    background-color: white;    
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* Sticky Header End */