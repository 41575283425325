/* Header Image */

.contact-header-title-image {
    background-image: url('../../Assets/Images/Image14.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    position: relative;
    isolation: isolate;
}

.contact-header-title-image::before {
    content: "";
    background-color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    inset: 0;
    z-index: -1;
}

.contact-header-title-image h1 {
    text-transform: uppercase;
    font: normal bold 50px 'Cinzel';
    cursor: pointer;
    transition: all 700ms ease-in-out;
}

.contact-header-title-image h1:hover {
    transition: all 700ms ease-in-out;
    transform: scale(1.2);
}

.contact-header-title-image p:hover {
    transition: all 700ms ease-in-out;
    transform: scale(1.2);
}

.contact-header-title-image h1::first-letter {
    font-size: 60px;
}

.contact-header-title-image p {
    transition: all 700ms ease-in-out;
    font-family: 'Poppins';
    text-transform: uppercase;
    cursor: pointer;
}

/* Header Image End */

/* Background Image */

.contact-page {
    background-image: url('../../Assets/Background/BackgroundPattern.jpg');
}

/* Background Image End */


/* Container */

.contact-container {
    padding: 5rem;
}

.contact-container-title h1 {
    font: normal 700 50px 'Cinzel';
}

.contact-container-card {
    padding: 3rem;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
}


.contact-container-card-mantine-card {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    cursor: pointer;
    transition: all 700ms ease-in-out;
}

.contact-container-card-mantine-card-icon {
    color: var(--blue-color);
    transition: all 700ms ease-in-out;
}

.contact-container-card-mantine-card:hover .contact-container-card-mantine-card-icon {
    transition: all 700ms ease-in-out;
    color: var(--background-color);
}

.contact-container-card-mantine-card:hover {
    transform: scale(1.2);
}

.contact-container-card-mantine-card h1 {
    font: normal 700 25px 'Cinzel';
}

.contact-container-card-mantine-card p {
    text-align: center;
    font: normal 500 15px 'Poppins';
}

/* Container End */






/* Map */

.contact-container-map {
    width: 100%;
    height: 80vh;
    padding: 2rem;
}

.contact-container-map iframe {
    width: 100%;
    height: 100%;
}


/* Map End */


/* Form */

.contact-form {
    padding: 4rem;
}

.contact-form-title h1 {
    font: normal 700 50px 'Cinzel';
}

.contact-form-container {
    width: 1000px;
    height: 100%;
    margin: auto;
    padding: 3rem;
}

.contact-form-container-inputs-1 {
    display: flex;
    gap: 2rem;
    padding: 1rem;
}

.contact-form-container-inputs-1-input {
    width: 100%;
}

.contact-form-container-inputs-text-area {
    width: 96%;
    margin: auto;
}


.contact-form-container-btn {
    background-color: var(--background-color);
    width: 96%;
    height: 5vh;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    transition: all 700ms ease-in-out;
}

.contact-form-container-btn:hover {
    background-color: var(--primary-color);
}

/* Form End */




@media (max-width:1450px) {
    .contact-container-card {
        width: 100%;
    }
}


@media(max-width:1250px) {
    .contact-container-map {
        padding: 0rem;
    }

    .contact-form {
        padding: 2rem;
    }

    .contact-form-container {
        width: 100%;
        padding: 0rem;
    }

    .contact-container-card {
        padding: 2rem 1rem;
    }

    .contact-container {
        padding: 2rem;
    }
}

@media(max-width:1060px) {
    .contact-container-card-mantine-card {
        width: 250px;
        height: 250px;
    }

    .contact-container-card-mantine-card h1 {
        font: normal 700 20px 'Cinzel';
    }

    .contact-container-card-mantine-card p {
        font: normal 500 12px 'Poppins';
    }

    .contact-form-title p {
        text-align: center;
    }

    .contact-container-title h1 {
        font: normal 700 40px 'Cinzel';
    }
}

@media(max-width:768px) {
    .contact-container-card {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .contact-container-card-mantine-card {
        width: 100%;
    }

    .contact-form {
        padding: 0;
    }

    .contact-form-title h1 {
        padding: 1rem 0;
        font: normal 700 30px 'Cinzel';
    }

    .contact-container-title h1 {
        font: normal 700 30px 'Cinzel';
    }

    .contact-form-container-inputs-1 {
        gap: 0.5rem;
    }

    .contact-container {
        padding: 1rem;
    }
}


@media(max-width:500px) {
    .contact-header-title-image {
        height: 35vh;
    }

    .contact-header-title-image h1 {
        font: normal bold 30px 'Cinzel';
    }

    .contact-header-title-image h1::first-letter {
        font-size: 40px;
    }

    .contact-form-container-inputs-1 {
        padding: 0.5rem 0;
        flex-direction: column;
    }

    .contact-form-title h1 {
        padding: 1rem 0 0 0;
        font: normal 700 25px 'Cinzel';
    }

    .contact-container-title h1 {
        font: normal 700 25px 'Cinzel';
    }

    .contact-container-card {
        padding: 1rem;
    }
    
.contact-container-map {
    height: 40vh;
}
}