/* Header Image */

.about-header-title-image {
    background-image: url('../../Assets/Images/Image1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    position: relative;
    isolation: isolate;
}

.about-header-title-image::before {
    content: "";
    background-color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    inset: 0;
    z-index: -1;
}

.about-header-title-image h1 {
    text-transform: uppercase;
    font: normal bold 50px 'Cinzel';
    cursor: pointer;
    transition: all 700ms ease-in-out;
}

.about-header-title-image h1:hover {
    transition: all 700ms ease-in-out;
    transform: scale(1.2);
}

.about-header-title-image p:hover {
    transition: all 700ms ease-in-out;
    transform: scale(1.2);
}

.about-header-title-image h1::first-letter {
    font-size: 60px;
}

.about-header-title-image p {
    transition: all 700ms ease-in-out;
    font-family: 'Poppins';
    text-transform: uppercase;
    cursor: pointer;
}

/* Header Image End */



/* About Content */

.about-container {
    width: 100%;
    height: 100%;
    background-image: url("../../Assets/Background/BackgroundPattern.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


.about-content {
    width: 100%;
    padding: 2rem 5rem;
    height: 100%;
    display: flex;
    gap: 3rem;
    align-items: center;
}

.about-content-para {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 3rem;
    justify-content: center;
}


.about-content-para-title-primary-color {
    color: var(--background-color);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.759);
}

.about-content-para-title h1 {
    font: normal 700 50px 'Cinzel';
}

.about-content-para-title p {
    font-family: 'Poppins';
    color: var(--blue-color);
}

.about-content-para-content h1 {
    font: normal 500 30px 'Poppins';
    padding-bottom: 1rem;
}

.about-content-para-badge ul li {
    display: flex;
    align-items: center;
    list-style-type: none;
    gap: 0.5rem;
    padding-bottom: 2rem;
    font-weight: 600;
    font-family: 'Cinzel';
}


.about-content-image {
    width: 900px;
    height: 650px;
}

.about-content-image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}



/* Flex Images  */

.about-content-flex-images {
    padding: 2px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-content-flex-img {
    position: relative;
    width: 400px;
    height: 400px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.about-content-flex-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.about-content-flex-img:nth-child(4) {
    top: 8rem;
    height: 330px;
}

.about-content-flex-img:nth-child(2) {
    top: 5rem;
}

.about-content-flex-img:nth-child(3) {
    bottom: 15rem;
}



/* Flex Images End */



/* Choose Us */

.about-choose-us-container {
    margin-top: 9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
    gap: 1rem;
}

.about-choose-us-container-title {
    text-align: center;
}

.about-choose-us-container-title h1 {
    font: normal 700 50px 'Cinzel';
}

.about-choose-us-container-title p {
    font-family: 'Poppins';
    color: var(--blue-color);
}

.about-choose-us-container-grid {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1rem;
    justify-content: center;
    padding: 3rem;
}

.about-choose-us-container-grid-card {
    width: 400px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    padding: 2rem;
    cursor: pointer;
}

.about-choose-us-container-grid-card-icon {
    width: 100px;
    height: 100px;
}

.about-choose-us-container-grid-card-icon img {
    width: 100%;
    height: 100%;
}

.about-choose-us-container-grid-card h1 {
    font: normal 600 30px 'Cinzel';
    transition: all 700ms ease-in-out;
    text-align: center;
}

.about-choose-us-container-grid-card p {
    font-family: 'Poppins';
    text-align: center;
}

.about-choose-us-container-grid-card:nth-child(1)::before {
    content: '';
    display: block;
    background: var(--blue-color);
    right: 0;
    width: 2px;
    height: 70%;
    position: absolute;
}

.about-choose-us-container-grid-card:nth-child(2)::before {
    content: '';
    display: block;
    background: var(--blue-color);
    right: 0;
    width: 2px;
    height: 70%;
    position: absolute;
}

.about-choose-us-container-grid-card:hover h1 {
    color: var(--blue-color);
    transition: all 700ms ease-in-out;
}


/* Choose Us End */



/* Contact us */

.about-contact-us-container {
    width: 100%;
    height: 50vh;
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.about-contact-us-container:hover .about-contact-us-container-content {
    background-color: rgba(0, 0, 0, 0.500);
}

.about-contact-us-container:hover .about-contact-us-container-image {
    transform: scale(1.2);
    transition: all 700ms ease-in-out;
}

.about-contact-us-container-image {
    transition: all 700ms ease-in-out;
    width: 100%;
    height: 100%;
}

.about-contact-us-container-image img {
    width: 100%;
    height: 100%;
}

.about-contact-us-container-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.300);
    width: 100%;
    height: 100%;
    color: white;
    transition: all 700ms ease-in-out;
}

.about-contact-us-container-content h1 {
    font: normal 700 40px 'Cinzel';
    text-align: center;
}

.about-contact-us-container-content p {
    font: normal 500 30px 'Lato';
}

.about-contact-us-container-content-button {
    background-color: var(--background-color);
    transition: all 300ms ease-in-out;
    height: 5vh;
    display: flex;
    gap: 1rem;
}

.about-contact-us-container-content-button:hover {
    transition: all 300ms ease-in-out;
    background-color: var(--primary-color);
}


/* Contact us End */

/* About Content End */



/* Media Query  */


@media(max-width:1500px) {
    .about-content-image {
        width: 700px;
        height: 650px;
    }

    .about-content-para {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 3rem;
        justify-content: center;
    }
}

@media(max-width:1300px) {
    .about-content {
        padding: 2rem;
    }

    .about-content-image {
        width: 500px;
        height: 500px;
    }

    .about-content-para-title h1 {
        font: normal 500 30px 'Cinzel';
    }

    .about-content-para-content h1 {
        font: normal 500 20px 'Poppins';
        padding-bottom: 1rem;
    }

}

@media(max-width:1060px) {
    .about-content {
        flex-direction: column;
        gap: 1rem;
    }

    .about-content-para {
        width: 100%;
        padding: 0;
        align-items: center;
        text-align: center;
    }

    .about-content-image {
        width: 100%;
        height: 100%;
    }

    .about-content-flex-images {
        display: grid;
        height: 100%;
        grid-template-columns: auto auto;
        padding: 2rem;
        gap: 1rem;
    }

    .about-content-flex-img:nth-child(4) {
        top: 0;
        height: 400px;
    }

    .about-content-flex-img:nth-child(2) {
        top: 0;
    }

    .about-content-flex-img:nth-child(3) {
        bottom: 0;
    }

    .about-choose-us-container {
        margin-top: 0;
    }

    .about-choose-us-container-grid {
        padding: 2rem;
    }
}


@media(max-width:850px) {
    .about-content-flex-img {
        width: 200px;
        height: 200px !important;
    }

    .about-choose-us-container-grid {
        flex-direction: column;
        gap: 2rem;
        padding: 1rem 2rem;
    }

    .about-choose-us-container-grid-card:nth-child(1)::before,
    .about-choose-us-container-grid-card:nth-child(2)::before {
        display: none;
    }

    .about-choose-us-container-grid-card {
        width: 100%;
        height: 100%;
        gap: 1rem;
        padding: 0;
    }
}


@media(max-width:500px) {

    .about-header-title-image {
        height: 35vh;
    }

    .about-header-title-image h1 {
        font: normal bold 30px 'Cinzel';
    }

    .about-header-title-image h1::first-letter {
        font: normal bold 40px 'Cinzel';
    }

    .about-content-para-badge ul li {
        gap: 0.5rem;
        padding-bottom: 0rem;
        font-size: 10px;
        font-weight: 400;
    }

    .about-content-para {
        gap: 1rem;
    }

    .about-content-para-content p {
        text-align: justify;
    }

    .about-content-para-badge ul li:nth-child(1) {
        padding-bottom: 1rem;
    }

    .about-content-flex-img {
        width: 170px;
        height: 170px !important;
    }

    .about-content-flex-images {
        padding: 0 1rem;
    }

    .about-choose-us-container-title h1 {
        font: normal 700 30px 'Cinzel';
    }
}

/* Media Query  End */