/* Header Image */

.gallery-header-title-image {
    background-image: url('../../Assets/Images/Image14.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    position: relative;
    isolation: isolate;
}

.gallery-header-title-image::before {
    content: "";
    background-color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    inset: 0;
    z-index: -1;
}

.gallery-header-title-image h1 {
    text-transform: uppercase;
    font: normal bold 50px 'Cinzel';
    cursor: pointer;
    transition: all 700ms ease-in-out;
}

.gallery-header-title-image h1:hover {
    transition: all 700ms ease-in-out;
    transform: scale(1.2);
}

.gallery-header-title-image p:hover {
    transition: all 700ms ease-in-out;
    transform: scale(1.2);
}

.gallery-header-title-image h1::first-letter {
    font-size: 60px;
}

.gallery-header-title-image p {
    transition: all 700ms ease-in-out;
    font-family: 'Poppins';
    text-transform: uppercase;
    cursor: pointer;
}

/* Header Image End */


/* Container */

.gallery-container {
    width: 100%;
    padding: 5rem;
    background-image: url('../../Assets/Background/BackgroundPattern.jpg');
    height: 100%;
}

.gallery-container-tabs {
    width: fit-content;
    margin: auto;
}

.gallery-container-tab-list {
    display: flex;
    /* gap: 1rem; */
}

.gallery-container-tabs-list-item,
.gallery-container-tabs-list-1st {
    padding: 0.5rem 2rem;
    font: normal 500 20px 'Lato';
    background-color: rgba(237, 236, 236, 0.45);
}


/* Gallery */

.gallery-container-gallery {
    width: 80%;
    margin: 5rem auto auto auto;
    display: grid;
    gap: 2rem;
    justify-content: center;
    grid-template-columns: auto auto auto;
}

.gallery-container-gallery-image {
    width: 400px;
    height: 350px;
    overflow: hidden;
    position: relative;
}


.gallery-container-gallery-image:nth-child(even) .gallery-container-gallery-image-animation {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.200);
    top: 0;
    left: 100%;
}

.gallery-container-gallery-image:nth-child(odd) .gallery-container-gallery-image-animation {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.200);
    top: 100%;
}

.gallery-container-gallery-image:nth-child(odd):hover .gallery-container-gallery-image-animation {
    top: 0;
    animation-name: topToBottom;
    animation-duration: 700ms;
    animation-timing-function: ease-in-out;
}

.gallery-container-gallery-image:nth-child(even):hover .gallery-container-gallery-image-animation {
    left: 0;
    animation-name: leftToRight;
    animation-duration: 700ms;
    animation-timing-function: ease-in-out;
}

@keyframes topToBottom {
    from {
        top: 100%;
    }

    to {
        top: 0;
    }

}

@keyframes leftToRight {
    from {
        left: 100%;
    }

    to {
        left: 0;
    }

}


.gallery-container-gallery-image img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all 700ms ease-in-out;
}


.gallery-container-gallery-image:hover img {
    transition: all 700ms ease-in-out;
    transform: scale(1.2);
}


.gallery-container-tabs-list-item:nth-child(1) {
    background-color: var(--background-color);
}

/* Container End */


@media(max-width:1300px) {
    .gallery-container-gallery-image {
        width: 300px;
        height: 250px;
    }
}

@media (max-width:1000px) {
    .gallery-container-gallery-image {
        width: 200px;
        height: 150px;
    }

    .gallery-container-gallery {
        padding-top: 3rem;
        margin: auto;
    }
}


@media(max-width:768px) {
    .gallery-container-tab-list {
        display: grid;
        grid-template-columns: auto auto auto;
    }

    .gallery-container {
        padding: 2rem;
    }
}

@media(max-width:700px) {
    .gallery-container-gallery {
        grid-template-columns: auto auto;
    }
}

@media(max-width:500px) {
    .gallery-header-title-image {
        height: 35vh;
    }

    .gallery-header-title-image h1 {
        font: normal bold 30px 'Cinzel';
    }

    .gallery-header-title-image h1::first-letter {
        font-size: 40px;
    }

    .gallery-container-gallery {
        gap: 1rem;
    }

    .gallery-container-tab-list {
        grid-template-columns: auto auto;
    }
}

@media (max-width:450px) {
    .gallery-container-gallery {
        grid-template-columns: auto auto;
    }

    .gallery-container-gallery-image {
        width: 170px;
        height: 120px;
    }
}